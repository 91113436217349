.huntgroup-list-container {
  /* Style the container */
  width: 50%; /* Adjust as needed */
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.huntgroup-list {
  /* Style the list */
  list-style: none;
  padding: 0;
}

.huntgroup-list li {
  /* Style the list items */
  padding: 10px;
  border-bottom: 1px solid #eee;
  cursor: pointer; /* Add cursor pointer for interactivity */
  &:hover {
    background-color: #f5f5f5;
  }
}

.create-huntgroup-form {
  margin-top: 20px;

  input[type="text"] {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  button[type="submit"] {
    background-color: #007bff;
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
}

.spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  margin: auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.huntgroup-details-container {
  display: flex;          /* Use flexbox for layout */
  gap: 20px;             /* Spacing between map and details */

  @media (max-width: 768px) { /* Adjust breakpoint as needed */
    flex-direction: column; /* Stack vertically on smaller screens */
  }
}

.huntgroup-map {
  flex: 2;              /* Map takes more space */
  height: 400px;         /* Set a default height */

  @media (min-width: 768px) {
    height: 600px;     /* Increase height on larger screens */
  }
}

.huntgroup-details {
  flex: 1;              /* Details take less space */
}