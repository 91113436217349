// _huntinggrounds.scss

.huntinggrounds-container {
    // Container styles
    padding: 20px;
  
    h2 {
      margin-bottom: 10px;
    }
  
    .create-huntingground-form {
      // Form styles
      display: flex;
      flex-direction: column;
      width: 300px; // Adjust width as needed
  
      div {
        margin-bottom: 10px;
      }
  
      label {
        margin-bottom: 5px;
      }
  
      input,
      select {
        padding: 5px;
        border: 1px solid #ccc;
      }
    }
  
    .huntingground-list {
      // List styles
      list-style: none;
      padding: 0;
  
      li {
        margin-bottom: 15px;
        border: 1px solid #eee;
        padding: 10px;
  
        h4 {
          margin-bottom: 5px;
        }
      }
    }
  
    .error-message {
      color: red;
      margin-top: 5px;
    }
  }