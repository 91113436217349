/* Style the forms */
.profile-container {
    background-color: #fff;
    /* White background */
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Add a subtle shadow */
    text-align: center;
    /* Center align content */
}

.profile-header {
    margin-bottom: 20px;
}

.profile-image {
    width: 150px;
    /* Adjust size as needed */
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    /* Maintain aspect ratio and cover the area */
    margin-bottom: 10px;
}

.profile-forms {
    text-align: left;
    /* Align forms to the left */
}

.profile-form {
    margin-bottom: 20px;
    /* Add spacing between forms */
}

.form-group {
    margin-bottom: 15px;
    /* Add spacing between form elements */
}

/* Style the input fields */
.form-control {
    border: 1px solid #ced4da;
    /* Add a border */
    padding: 8px 12px;
    /* Add padding */
    border-radius: 4px;
    /* Add rounded corners */
}

.form-control-file {
    padding: 5px;
    /* Add some padding */
}

/* Style the buttons */
.btn {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.btn-primary {
    background-color: #007bff;
    /* Blue color */
    color: white;
}

.btn-secondary {
    background-color: #6c757d;
    /* Gray color */
    color: white;
}

.btn:disabled {
    opacity: 0.65;
    /* Reduce opacity when disabled */
    cursor: not-allowed;
}

/* Style the profile image */
.profile-image {
    margin-top: 10px;
    border-radius: 50%;
    /* Make it circular */
    border: 2px solid #ddd;
    /* Add a border */
}